// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-index-js": () => import("/opt/build/repo/src/Templates/LandingPage/index.js" /* webpackChunkName: "component---src-templates-landing-page-index-js" */),
  "component---src-templates-product-detail-page-index-js": () => import("/opt/build/repo/src/Templates/ProductDetailPage/index.js" /* webpackChunkName: "component---src-templates-product-detail-page-index-js" */),
  "component---src-templates-article-detail-page-index-js": () => import("/opt/build/repo/src/Templates/ArticleDetailPage/index.js" /* webpackChunkName: "component---src-templates-article-detail-page-index-js" */),
  "component---src-templates-tour-detail-page-index-js": () => import("/opt/build/repo/src/Templates/TourDetailPage/index.js" /* webpackChunkName: "component---src-templates-tour-detail-page-index-js" */),
  "component---src-templates-tour-listing-page-index-js": () => import("/opt/build/repo/src/Templates/TourListingPage/index.js" /* webpackChunkName: "component---src-templates-tour-listing-page-index-js" */),
  "component---src-templates-product-listing-page-index-js": () => import("/opt/build/repo/src/Templates/ProductListingPage/index.js" /* webpackChunkName: "component---src-templates-product-listing-page-index-js" */),
  "component---src-templates-article-listing-page-index-js": () => import("/opt/build/repo/src/Templates/ArticleListingPage/index.js" /* webpackChunkName: "component---src-templates-article-listing-page-index-js" */),
  "component---src-templates-new-order-detail-page-index-js": () => import("/opt/build/repo/src/Templates/NewOrderDetailPage/index.js" /* webpackChunkName: "component---src-templates-new-order-detail-page-index-js" */),
  "component---src-templates-profile-page-index-js": () => import("/opt/build/repo/src/Templates/ProfilePage/index.js" /* webpackChunkName: "component---src-templates-profile-page-index-js" */),
  "component---src-templates-new-cart-page-index-js": () => import("/opt/build/repo/src/Templates/NewCartPage/index.js" /* webpackChunkName: "component---src-templates-new-cart-page-index-js" */),
  "component---src-templates-new-checkout-page-index-js": () => import("/opt/build/repo/src/Templates/NewCheckoutPage/index.js" /* webpackChunkName: "component---src-templates-new-checkout-page-index-js" */),
  "component---src-templates-select-cvs-page-index-js": () => import("/opt/build/repo/src/Templates/SelectCvsPage/index.js" /* webpackChunkName: "component---src-templates-select-cvs-page-index-js" */),
  "component---src-templates-social-login-result-page-index-js": () => import("/opt/build/repo/src/Templates/SocialLoginResultPage/index.js" /* webpackChunkName: "component---src-templates-social-login-result-page-index-js" */),
  "component---src-templates-search-result-page-index-js": () => import("/opt/build/repo/src/Templates/SearchResultPage/index.js" /* webpackChunkName: "component---src-templates-search-result-page-index-js" */),
  "component---src-templates-test-page-index-js": () => import("/opt/build/repo/src/Templates/TestPage/index.js" /* webpackChunkName: "component---src-templates-test-page-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

